import React from "react"
import Layout from '../components/layout'
import styled from "styled-components"
import H2 from '../components/typography/h2'
import { graphql, Link } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"
import ContactForm from '../components/contact-form'
import {Helmet} from "react-helmet";


const homepage = ({data}) => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Collins Sporthorses</title>
                <meta name="description" content="Collins Sporthorses specializes in the training and boarding of young sport horses. We also have quality trained horses for sale." />
            </Helmet>
            <SlideImage>
                <Img
                    fluid={data.hero.childImageSharp.fluid}
                    objectFit='cover'
                    objectPosition='50% 15%'
                    alt='horses'
                    style={{ width: "100%", height: "100%" }}
                />
            </SlideImage>

            <Wrapper>
                <Content>
                    <h2>We're Collins Sporthorses</h2>
                    <p>Boarding. Training. Sales.</p>
                </Content>
            </Wrapper>

            <section>
                <Features>
                    <div className="feature-image">
                        <div className="feature-headline">
                            <h3>Kids Camps</h3>
                        </div>
                        <Link to="/services">
                        <Img fluid={data.kids.childImageSharp.fluid} style={{width: "100%"}} />
                        </Link>
                    </div>
                    <div className="feature-image">
                        <div className="feature-headline">
                            <h3>Showing</h3>
                        </div>
                        <Link to="/services">
                        <Img fluid={data.horseShowing.childImageSharp.fluid} style={{width: "100%"}} />
                        </Link>
                    </div>
                    <div className="feature-image">
                        <div className="feature-headline">
                            <h3>Professional Riding</h3>
                        </div>
                        <Link to="/services">
                        <Img fluid={data.horseSales.childImageSharp.fluid} style={{width: "100%"}} />
                        </Link>
                    </div>
                    <div className="feature-image">
                        <div className="feature-headline">
                            <h3>Horses for Sale</h3>
                        </div>
                        <Link to="/horses-for-sale">
                        <Img fluid={data.sales.childImageSharp.fluid} style={{width: "100%"}} />
                        </Link>
                    </div>
                </Features>
            </section>

            <section>
                <Container>
                    <About>
                        <div className="about-image">
                            <Img fluid={data.about.childImageSharp.fluid} style={{width: "100%"}} />
                        </div>
                        <div className="about-paragraph">
                            <H2>A little bit about us</H2>
                            <p>Melissa’s passion for horses began at a very young age and has developed into a career dedicated to
horse and rider. She continues to lesson and clinic with top professionals developing a unique approach
to her training style and continuing to advance up the levels in hunter, jumpers and equitation. Melissa
moved to the Western KY area to complete an agriculture degree from Murray State University and ride
on the IHSA Equestrian team.</p>
                        </div>
                    </About>
                </Container>
            </section>

            <section>
                <Container>
                    <Sponsors>
                        <H2 textAlign="center">Sponsors</H2>
                        <a href="http://voltairedesign.com/" target="__blank"><Img fluid={data.voltaire.childImageSharp.fluid} style={{width: "100%"}} /></a>
                    </Sponsors>
                </Container>
            </section>

            <section>
                <Container>
                    <Contact>
                        <H2 textAlign="center">Contact Us</H2>
                        <ContactForm formName="Contact Form" buttonName="Contact Us" />
                    </Contact>
                </Container>
            </section>

        </Layout>
        
    )
};

export default homepage;

const SlideImage = styled.div`
    position: absolute;
    width: 100%;
    z-index: 0;
    height: 400px;
    h2 {
        font-family: 'Playfair Display', serif;
    }
    @media (min-width: 800px) {    
        height: 640px;
    }
`;

const Wrapper = styled.div`
    color: #fff;
    height: 400px;
    margin: auto;
    max-width: 85%;
    position: relative;
    width: 85%;
    z-index: 1;
    a {
        text-decoration: none;
    }
    @media (min-width: 800px) {
        height: 640px;
        max-width: 1600px;
        width: 85%;
    }
`;

const Content = styled.div`
    max-width: 300px;
    position: absolute;
    text-align: left;
    top: 15%;
    text-shadow: 2px 0px 10px rgba(0,0,0,0.74);
    h2 {
        font-family: 'Playfair Display', serif;
        font-size: 2.2em;
    }
    p {
      font-size: 1.5em;
      font-weight: 700; 
    }
    @media (min-width: 800px) {
        max-width: 475px;
        h2 {
            font-size: 4em;
            margin-bottom: 25px;
        }
    }
`;

const Container = styled.div`
    width: 1000px;
    max-width: 90%;
    margin 0 auto;
`;


const Features = styled.div`
    
    .feature-image {
        position: relative;
        margin: 1em;
        .feature-headline {
            position: absolute;
            color: white;
            z-index: 1;
            bottom: .5em;
            left: 2em;
            font-size: 1.25em;
        }
    }
    @media (min-width: 650px) {
        display: flex;
        .feature-image {
            width: calc(100% / 4 - 5px);
            padding: 5px;
            margin: 0;
            :nth-child(even) {
                padding: 5px 0px;
            }
        }
        }
    }
`;

const About = styled.div`
    p {
        font-family: 'Lora';
        font-size: 1.2em;
        line-height: 1.75em;
    }
    .about-paragraph {
        margin-top: 1em;
    }
    margin: 4em 0;
    @media (min-width: 800px) {
        display: flex;
        .about-image {
            width: calc(100% * .45 - 50px);
            padding-right: 50px;
        }
        .about-paragraph {
            width: calc(100% * .55);
        }
    }
}
`;

const Sponsors = styled.div`
    width: 100%;
    max-width: 400px;
    margin: 4em auto;
`

const Contact = styled.div`
    margin-bottom: 4em;
    @media (min-width: 800px) {
        margin: 4em;
    }
`


export const query = graphql`
query {
  hero: file(relativePath: { eq: "images/hero-2.JPG" }) {
    childImageSharp {
      fluid(maxWidth: 1500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  kids: file(relativePath: { eq: "images/kids-camp.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  horseShowing: file(relativePath: { eq: "images/horse-showing@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  horseSales: file(relativePath: { eq: "images/horses-for-sale.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  sales: file(relativePath: { eq: "images/horse-sale.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  about: file(relativePath: { eq: "images/about.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  voltaire: file(relativePath: { eq: "images/voltaire.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
